import React from "react"
import {YMaps, Map, Placemark} from 'react-yandex-maps'

const ContactMap = ({coordinates}) => (
  <div>
    <YMaps>
      <div className="bg-info">
        <Map
          defaultState={{center: coordinates, zoom: 17}}
          width="100%" 
        >
          <Placemark
            geometry={coordinates}
          />
        </Map>
      </div>
    </YMaps>
  </div>
);

export default ContactMap;